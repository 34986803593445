import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Checking for known issues</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Creating an issue</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Requests for comment</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Need more help?</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "checking-for-known-issues"
    }}>{`Checking for known issues`}</h2>
    <h3 {...{
      "id": "github"
    }}>{`Github`}</h3>
    <p>{`We use Github to track our bugs. If you have a bug to report or wish to request
a new feature, please check the
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/issues"
      }}>{`existing issues`}</a>{` before
opening a new one. There may already be something similar in the works.`}</p>
    <h3 {...{
      "id": "carbon-website"
    }}>{`Carbon website`}</h3>
    <p>{`Please take some time to explore the content on this website before opening an
issue. The site is comprehensive and most guidelines and components are well
documented.`}</p>
    <h2 {...{
      "id": "creating-an-issue"
    }}>{`Creating an issue`}</h2>
    <h3 {...{
      "id": "github-issues"
    }}>{`GitHub issues`}</h3>
    <p>{`Report bugs, request features, and leave feedback with a GitHub issue. If you’re
unsure which repo to use, please open an issue in the
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon"
      }}>{`Carbon monorepo`}</a>{`.`}</p>
    <h4 {...{
      "id": "carbon-repos"
    }}>{`Carbon repos`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-website/issues/new/choose"
        }}>{`Carbon website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon"
        }}>{`Carbon monorepo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-components-angular/issues/new"
        }}>{`Carbon Angular`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-components-vue/issues/new"
        }}>{`Carbon Vue`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-charts/issues/new"
        }}>{`Carbon charts (beta)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-design-kit/issues/new"
        }}>{`Carbon design kit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-icons/issues/new"
        }}>{`Carbon icons`}</a></li>
    </ul>
    <h3 {...{
      "id": "github-pull-requests"
    }}>{`GitHub pull requests`}</h3>
    <p>{`If you have a specific fix or contribution, start by generating a pull request
in the appropriate
`}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/resources#development-resources"
      }}>{`Carbon repo`}</a>{`.
Here are detailed
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Creating-a-PR-through-github.com-UI"
      }}>{`instructions`}</a>{`
for forking the repo and opening a pull request.`}</p>
    <h2 {...{
      "id": "requests-for-comment"
    }}>{`Requests for comment`}</h2>
    <p>{`For changes that are larger in scale, an RFC (request for comment) may be
appropriate. Read more about our
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/rfcs"
      }}>{`RFC process`}</a>{`.`}</p>
    <h2 {...{
      "id": "need-more-help"
    }}>{`Need more help?`}</h2>
    <p>{`If you have more questions about issues or requesting a features, there are
multiple ways to reach us at
`}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/help/support"
      }}>{`Support`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      